import React, { useState } from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';

import { Book, CashStack, Chat, DoorClosed, Globe, InfoCircle, Link45deg, List, QuestionCircle, Search, Sim } from 'react-bootstrap-icons'

// import { SiSwagger } from "react-icons/si";

import logo from './assets/logo.svg';

// import './fonts/montserrat.css'
// import './fonts/charis.css'
import './fonts/notosans.css'
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Config from './components/Config/Config';
import FAQs from './components/FAQs/FAQs';
import Glossary from './components/Glossary/Glossary';
import InternationalQuote from './components/InternationalQuote/InternationalQuote';
import Login from './components/Login/Login';
import DefaultMode from './components/Mode/DefaultMode';
import PageNotFound from './components/PageNotFound/PageNotFound';
import RateSheet from './components/RateSheet/RateSheet';
import Simulation from './components/Simulation/Simulation';
import SimulationResults from './components/SimulationResults/SimulationResults';
import Support from './components/Support/Support';
// import Swagger from './components/Swagger/Swagger';

import useMode from './hooks/useMode';
import useSimulationResultsCache from './hooks/useSimulationResultsCache';
import useTokens from './hooks/useTokens';


import ChatDialog from './dialogs/ChatDialog';

const FloatingChatBotIcon = ({ onClick }) => {
    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            cursor: 'pointer',
            width: '75px',
        }} onClick={onClick} className='d-print-none'>
            <button id="chatbot_open_button" style={{"fontSize": "1.5rem"}} className="btn btn-info btn-lg"><Chat  /></button>
        </div>
    );
};

function App() {

    const { mode, setMode } = useMode();
    const { setNonce, nonce,
            setTokenAccess, tokenAccess, tokenAccessDecode, tokenAccessExpired
        ,   setTokenRefresh, tokenRefresh, tokenRefreshDecode, tokenRefreshExpired
     } = useTokens();
    const { addSimulationResult, simulationResultsCache } = useSimulationResultsCache();

    console.log(tokenAccessDecode);
    console.log(tokenRefreshDecode);

    // ************************************************************************
    // Modals Chat

    const [modal_chatbot_dialog_open, setChatBotDialogOpen] = useState(false);
    const [modal_chatbot_context, setChatBotContext] = useState(null);

    const handleOnClickChatBot = () => {
        setChatBotDialogOpen(true);
    }

    // ************************************************************************
    // offcanvas menu (for mobile apps)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // ************************************************************************
    // api

    const handleAPIRequest = async (url, options = {}, authorized = true) => {

        if (!options.headers) {
            options.headers = {};
        }

        if (authorized && !options.headers['Authorization']) {
            options.headers['Authorization'] = 'Bearer ' + tokenAccess;
        }

        if (!options.method) {
            options.method = 'GET';
        }

        if (options.method === 'POST') {
            if (!options.headers['Content-Type']) {
                options.headers['Content-Type'] = 'application/json';
            }
            if (!options.body) {
                throw new Error("The options object must have a 'body' field.");
            }
        }

        const res = await fetch(url, options);

        if (authorized && res.status === 401) {

            const res_refresh = await fetch('/api/v1/refresh', {
                method: 'POST'
            ,   headers: {
                    'Content-Type': 'application/json'
                }
            ,   body: JSON.stringify({ refresh_token: tokenRefresh })
            });

            if (res_refresh.status !== 200) {
                onLogout();
            }

            const res_data = await res_refresh.json();
            setTokenAccess(res_data.access_token);
            setTokenRefresh(res_data.refresh_token);

            options.headers['Authorization'] = 'Bearer ' + res_data.access_token;

            const res_with_refresh = await fetch(url, options);
            if (res_with_refresh.status === 401) {
                onLogout();
            }
            return res_with_refresh
        }
        else {
            return res;
        }
    }


    // ************************************************************************
    // start render

    if (!tokenAccessDecode || !tokenRefresh || !tokenRefreshDecode || tokenRefreshExpired) {
        return <Login setTokenAccess={ setTokenAccess } setTokenRefresh={ setTokenRefresh } setNonce={ setNonce } nonce={ nonce } />
    }

    const onLogout = async () => {

        const res_logout = await fetch('/api/v1/logout', {
            method: 'POST'
        ,   headers: {
                'Content-Type': 'application/json'
            }
        ,   body: JSON.stringify({ refresh_token: tokenRefresh })
        });

        setTokenAccess(null);
        setTokenRefresh(null);
    }

    const handleOnClickLogout = async e => {
        e.preventDefault();
        onLogout();
    }
    
    // ************************************************************************
    // For Testing Refresh

    // const onRefresh = async () => {

    //     const res_refresh = await fetch('/api/v1/refresh', {
    //         method: 'POST'
    //     ,   headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     ,   body: JSON.stringify({ refresh_token: tokenRefresh })
    //     });

    //     if (res_refresh.status === 200) {
    //         const res_data = await res_refresh.json();
    //         setTokenAccess(res_data.access_token);
    //         setTokenRefresh(res_data.refresh_token);
    //     }
    // }

    // const handleOnClickRefresh = async e => {
    //     e.preventDefault();
    //     onRefresh();
    // }


    return (

        <>
            <DefaultMode handleAPIRequest={ handleAPIRequest } setMode={ setMode } />

            <ChatDialog
                show={ modal_chatbot_dialog_open }
                onHide={ () => setChatBotDialogOpen(false) }
                size="xl"
                username={ tokenAccessDecode['given_name'] }
                handleAPIRequest={ handleAPIRequest }
                chatContext={ modal_chatbot_context }
            />

            <header className="navbar sticky-top bg-light flex-md-nowrap p-0 shadow d-md-none d-print-none">

                <div className="mx-auto d-block flex-row d-md-none px-3 py-3" href="/">
                    <a href="/"><img src={logo} className="img-responsive navbar-logo" alt="HealthRate Logo" /></a>
                </div>

                <ul className="navbar-nav flex-row d-md-none">
                    <li className="nav-item text-nowrap">
                        <button className="nav-link px-3 text-accent" type="button" onClick={handleShow} aria-controls="sidebarMenu" aria-expanded={show} aria-label="Toggle navigation">
                            <List />
                        </button>
                    </li>
                </ul>

            </header>

            <div className="container-fluid">

                <div className="row">

                    <div className="navbar-full-view sidebar border border-right col-md-3 col-lg-2 p-0 text-light bg-accent d-print-none">

                        <Offcanvas show={show} onHide={handleClose} responsive='md'>
                            <Offcanvas.Header closeButton></Offcanvas.Header>
                            <Offcanvas.Body className='d-md-flex flex-column p-0 overflow-y-auto'>

                                <div className='nav bg-light mx-0' style={{borderRadius: "0px"}}>
                                    <div className="mx-auto px-3 py-3" >
                                        <a href="/"><img src={logo} className="img-responsive navbar-logo" alt="HealthRate Logo" /></a>
                                    </div>
                                </div>

                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/">
                                            <Sim />
                                            Simulation
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/results">
                                            <Search />
                                            Results
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/rate_sheets">
                                            <CashStack />
                                            Rate Sheets
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/international_quote">
                                            <Globe />
                                            International Quote
                                        </a>
                                    </li>

                                </ul>

                                <hr className="my-3" />

                                <ul className="nav flex-column mb-auto">
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/faqs">
                                            <QuestionCircle />
                                            FAQs
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/HealthRate_SaaS_Get_Started_Guide.pdf" target="_blank">
                                            <InfoCircle />
                                            User Guide
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/swagger" target="_blank">
                                            <SiSwagger />
                                            Swagger
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/glossary">
                                            <Book />
                                            Glossary
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2 active" aria-current="page" href="/support">
                                            <Link45deg />
                                            Support
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={handleOnClickLogout}>
                                        <a className="nav-link d-flex align-items-center gap-2" href="/">
                                            <DoorClosed />
                                            Sign Out
                                        </a>
                                    </li>
                                    {/* <li className="nav-item" onClick={handleOnClickRefresh}>
                                        <a className="nav-link d-flex align-items-center gap-2" href="/">
                                            <DoorClosed />
                                            Refresh
                                        </a>
                                    </li> */}
                                </ul>

                            </Offcanvas.Body>

                        </Offcanvas>
                    
                    </div>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                        <div className='d-none d-print-block'><img src={logo} className="img-responsive navbar-logo mt-3" alt="HealthRate Logo" /></div>

                        <BrowserRouter>
                            <Routes>
                                <Route path="*" element={<PageNotFound />} />
                                <Route path="/" element={<Simulation handleAPIRequest={ handleAPIRequest } addSimulationResult={ addSimulationResult } mode={ mode } />} />
                                <Route path="/config" element={<Config handleAPIRequest={ handleAPIRequest } mode={ mode } setMode={ setMode }/>} />
                                <Route path="/faqs" element={<FAQs />} />
                                <Route path="/glossary" element={<Glossary />} />
                                <Route path="/international_quote" element={<InternationalQuote handleAPIRequest={ handleAPIRequest }/>} />
                                <Route path="/rate_sheets" element={<RateSheet handleAPIRequest={ handleAPIRequest } setChatBotContext={ setChatBotContext } />} />
                                <Route path="/rate_sheets/:provider_id/:network_id/:service_type_id" element={<RateSheet handleAPIRequest={ handleAPIRequest } setChatBotContext={ setChatBotContext } />} />
                                <Route path="/results" element={<SimulationResults simulationResultsCache={ simulationResultsCache } setChatBotContext={ setChatBotContext } mode={ mode} />} />
                                <Route path="/results/:simulation_result_id" element={<SimulationResults simulationResultsCache={ simulationResultsCache } setChatBotContext={ setChatBotContext } mode={ mode} />} />
                                <Route path="/support" element={<Support />} />
                                {/* <Route path="/swagger" element={<Swagger handleAPIRequest={ handleAPIRequest } />} /> */}
                            </Routes>
                        </BrowserRouter>

                    </main>

                    { !modal_chatbot_dialog_open ? ( <FloatingChatBotIcon onClick={handleOnClickChatBot} />)  : ( <></> ) }

                </div>
            </div>
        </>

    );
}

export default App;
